import { useRef, useState } from "react"
import { IoPersonOutline, IoMailOutline, IoCallOutline, IoLocationOutline, IoSend } from "react-icons/io5"
import emailjs from '@emailjs/browser'


const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const PHONE_REGEX = /^[6-9]\d{9}$/;


const Card = () => {

    
    const userRef = useRef()
    const msgRef = useRef()

    const [location, setLocation] = useState('')

    const [email, setEmail] = useState('')
   
    const [phone, setPhone] = useState('')
    
    const [person, setPerson] = useState('')

    const [date, setDate] = useState('')

    const [budget, setBudget] = useState(5000)

    const [stateMessage, setStateMessage] = useState('');

    const sendEmail = async(e) => {
        e.preventDefault()

        const eml = EMAIL_REGEX.test(email)
        const phn = PHONE_REGEX.test(phone)

        if(!eml || !phn){
            setStateMessage('Invalid Entry')
            return
        }

        const templateParams = {
            email: email,
            phone: phone,
            date: date,
            location: location,
            person: person,
            budget: budget
          }

        
        try{
            const success = await emailjs.send(process.env.REACT_APP_SERVICE_CODE, process.env.REACT_APP_TEMPLATE_CODE, templateParams, {
                publicKey: process.env.REACT_APP_PUBLIC_KEY
              })
    
            if(success.status === 200){
                setStateMessage('Message sent successfully');
                setBudget(5000)
                setEmail('')
                setDate('')
                setLocation('')
                setPerson('')
                setPhone('')
                msgRef.current.focus()
            }

        }
        catch(err){
            console.log(err);
            setStateMessage(`Form data not submitted.`);
            msgRef.current.focus()
        }
    }
    


    return(
        <>
        
        <p ref={msgRef} >{stateMessage}</p>
        
        <form className='card grid' onSubmit={sendEmail}>

            <div className='destinationInput'>
                <label htmlFor="place">
                    Search your destination
                </label>
                <div className="input flex">
                    <input 
                        ref={userRef}
                        type='text' 
                        id="place"
                        placeholder='Location'
                        autoComplete="off"
                        value={location}
                        onChange={(e)=>setLocation(e.target.value)}
                    />
                    <IoLocationOutline className='icon'/>
                </div>
            </div>

            <div className='emailInput'>
                <label htmlFor="email">
                    Your Email
                </label>
                <div className="input flex">
                    <input 
                        ref={userRef}
                        type='email' 
                        id="email"
                        placeholder="Email"
                        autoComplete="off"
                        required
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                    <IoMailOutline className="icon"/>
                </div>
            </div>

            <div className='phoneInput'>
                <label htmlFor="phone">
                    Your Phone Number
                </label>
                <div className="input flex">
                    <input 
                        ref={userRef}
                        type='text' 
                        id="phone"
                        placeholder="Phone Number"
                        autoComplete="off"
                        required
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
                    />
                    <IoCallOutline className="icon" />
                </div>
            </div>

            <div className='personInput'>
                <label htmlFor="person">
                    Total Person
                </label>
                <div className="input flex">
                    <input 
                        ref={userRef}
                        type='text' 
                        id="person"
                        placeholder="Person"
                        autoComplete="off"
                        value={person}
                        onChange={(e)=>setPerson(e.target.value)}
                    />
                    <IoPersonOutline className="icon"/>
                </div>
            </div>
            

            <div className='dateInput'>
                <label htmlFor="date">
                    Select your date
                </label>
                <div className="input flex">
                    <input 
                        ref={userRef}
                        type='date' 
                        id="date"
                        value={date}
                        onChange={(e)=>setDate(e.target.value)}
                    />
                </div>
            </div>

            <div className='priceInput'>
                <div className="label_total flex">
                <label>
                    Budget
                </label>
                <h3 className='total'>{budget}</h3>
                </div>

                <div className="input flex">
                <label htmlFor="price"></label>
                    <input 
                        ref={userRef}
                        type='range' 
                        id="price"
                        max="50000" 
                        step="1000"
                        min="5000"
                        value={budget}
                        onChange={(e)=>setBudget(e.target.value)}
                    />
                </div>
            </div>

            <button className="searchOptions flex" onClick={sendEmail}>
                <IoSend className='icon'/>
                <span>ENQUIRE</span>
            </button>
                

        </form>
        </>
    )
}

export default Card