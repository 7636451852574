import './home.css'
import Main from './Main'
import Popular from "../Popular/Popular"
import Special from "../Special/Special"
import Hot from "../Hot/Hot"
// import Poster from '../Poster/Poster'

const Home = () => {
    return(
        <>
            <Main/>
            <Special/>
            {/* <Poster/> */}
            <Popular/>
            <Hot/>
        </>
    )
}

export default Home