import './header.css'
import { useState } from "react";
import { TbGridDots } from "react-icons/tb";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";


const Header = () => {


    // For handling the active state of navbar
    const [active, setActive] = useState('navbar')

    // Method for marking navbar active
    const handleShowNavbar = () => {
        setActive('navbar activeNavbar')
    }

    // Method for unmarking navbar active status
    const handleHideNavbar = () => {
        setActive('navbar')
    }

    
    

    return(
        <nav className="navbarSection">
            <div className="mainNav flex">

                <div className="logo">
                    <Link to="/" className="logo flex">
                        <img src='/assets/logo.png' alt="logo"/>
                    </Link>
                </div>

                <div className={active}>
                    <ul className="navList flex">
                        <li className="navItem">
                            <NavLink to="/" className="navLink">Home</NavLink>
                        </li>

                        <li className="navItem">
                            <NavLink to="/about" className="navLink">About</NavLink>
                        </li>

                        <li className="navItem">
                            <NavLink to="/packages" className="navLink">Packages</NavLink>
                        </li>

                        <li className="navItem">
                            <NavLink to="/destinations" className="navLink">Destinations</NavLink>
                        </li>

                        <li className="navItem">
                            <NavLink to="/contact" className="navLink">Contact</NavLink>
                        </li>
                        

                    </ul>

                    <div onClick={handleHideNavbar} className="closeNavbar">
                        <IoIosCloseCircleOutline className="icon"/>
                    </div>
                </div>

                <div onClick={handleShowNavbar} className="toggleNavbar">
                    <TbGridDots className="icon"/>
                </div>

            </div>

        </nav>
    )
}

export default Header