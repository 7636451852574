import './footer.css'
import { RiMailSendLine } from "react-icons/ri";
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useState } from 'react';


const Footer = () => {

    const dest = 'destinations@explorersunited.in'
    const subject = "Visitor's Message"
    const [body, setBody] = useState("")

    return (
        <footer className="footer">
            <div className="videoFooter">
                <video src='/assets/video (2).mp4' loop autoPlay muted type="video/mp4"></video>
            </div>

            <div className='sectionContent container'>
                <div className='contactFooter flex'>
                    <div className='text'>
                        <small>KEEP IN TOUCH</small>
                        <h2>Travel with us</h2>
                    </div>

                    <div className='inputFooter flex'>
                        <input type='text' placeholder='Message' value={body} onChange={(e)=>setBody(e.target.value)}/>
                        
                            <Link to={`mailto:${encodeURIComponent(dest)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} className='btn flex'><span>SEND</span><RiMailSendLine className='icon'/></Link>
                        
                    </div>
                </div>

                <div className='footerCard flex'>
                    <div className='footerIntro flex'>
                        <div className='logoFooter'>
                            <Link to='/' className='logo flex'>
                                <img src='/assets/logo.png' alt="logo"/>
                            </Link>
                        </div>
                        <div className='footerParagraph'>
                            Welcome to Explorers United, where we believe in transforming your travel dreams into unforgettable experiences amidst the mesmerizing beauty of the Kashmir Valley.
                            <div className='footerContact'>
                                <span>Email:</span> destinations@explorersunited.in<br/>
                                <span>Address:</span> Baba Demb, Naqashpora, Srinagar, 190001
                            </div>
                        </div>

                        <div className='footerSocials flex'>
                            <Link to={``}><FaYoutube className='icon'/></Link>
                            <Link to={`https://www.facebook.com/profile.php?id=61557512890467`}><FaFacebook className='icon'/></Link>
                            <Link to={`https://www.instagram.com/explorersunitedco/`}><FaInstagram className='icon'/></Link>
                            <Link to={`https://www.linkedin.com/company/101684509/`}><FaLinkedin className='icon'/></Link>
                        </div>
                    </div>

                    <div className='footerLinks grid'>
                        {/* Group One */}
                        <div className='linkGroup'>
                            <span className='groupTitle'>
                                Our Agency
                            </span>

                            <li className='footerList flex'>
                            <FaChevronRight className='icon'/>
                            Services
                            </li>
                            
                            <li className='footerList flex'>
                            <FaChevronRight className='icon'/>
                            Tourism
                            </li>

                            <li className='footerList flex'>
                            <FaChevronRight className='icon'/>
                            Refund Policy
                            </li>


                            <li className='footerList flex'>
                            <FaChevronRight className='icon'/>
                            Insurance
                            </li>

    
                        </div>

                       

                        {/* Group Two */}
                        <div className='linkGroup'>
                            <span className='groupTitle'>
                                Last Minute
                            </span>

                            <li className='footerList flex'>
                            <FaChevronRight className='icon'/>
                                Gulmarg
                            </li>

                            <li className='footerList flex'>
                            <FaChevronRight className='icon'/>
                                Pahalgam
                            </li>

                            <li className='footerList flex'>
                            <FaChevronRight className='icon'/>
                                Kashmir
                            </li>

                            <li className='footerList flex'>
                            <FaChevronRight className='icon'/>
                                Ladakh
                            </li>

                        </div>
                    </div>

                    <div className='footerLabel flex'>
                        <small> COPYRIGHT RESERVED &copy; {new Date().getFullYear()}</small>
                    </div>
                    
                </div>
            </div>
        </footer>
    )
}

export default Footer