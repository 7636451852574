import { Suspense, lazy } from "react"
import { Route, Routes } from "react-router-dom"

// import HeaderTile from "./components/Header/HeaderTile"
import Header from "./components/Header/Header"
import Home from "./components/Home/Home"
import Footer from "./components/Footer/Footer";
import WhatsAppButton from "./components/WhatsApp/WhatsAppButton";
import MainLayout from "./components/Layout/MainLayout"


const Missing = lazy(()=>import("./components/Missing/Missing"))
const Package = lazy(()=>import("./components/Pack/Pack")) 
const PackDetails = lazy(()=>import("./components/PackDetails/PackDetails")) 
const Destination = lazy(()=>import("./components/Destination/Destination")) 
const DestinationDetails = lazy(()=>import("./components/Destination/DestinationDetails")) 
const ContactPage = lazy(()=>import("./components/Contact/Contact")) 
const About = lazy(()=>import("./components/About/About"))
// import PersistLogin from "./components/Layout/PersistLogin"


function App() {
  return (
      <>
        {/* <HeaderTile/> */}
        <Header/>
        
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
                        
              

                {/* Public Routes */}
                <Route path="/" element={<MainLayout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="about" element={<About/>}/>

                    <Route path="packages" >
                      <Route index element={<Package/>}/>
                      <Route path="details/:id" element={<PackDetails/>}/>
                        
                    </Route>

                    
                    <Route path="destinations" >
                      <Route index element={<Destination/>}/>
                      <Route path="details/:id" element={<DestinationDetails/>}/>
                        
                    </Route>

                    <Route path="contact" element={<ContactPage/>}/>

          

                </Route>
                        
                <Route path="*" element={<Missing/>}/>
                {/* End Public Routes */}
              
          
          </Routes>
          </Suspense>
        
        <WhatsAppButton />
        <Footer/>
      </>
  )
}

export default App
