import './whatsapp.css'
import { FaWhatsapp } from "react-icons/fa"

const WhatsAppButton = () => {
    const sendMessage = () => {
        const phoneNumber = '919797914593' 
        const message = 'Hello!' 
        const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`
        window.open(url, '_blank')
    }

    return (
        <button className="whatsapp" onClick={sendMessage}>
            <FaWhatsapp className="whatsappIcon" />
        </button>
        
    )
}

export default WhatsAppButton