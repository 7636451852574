import '../Special/main.css'
import PackageItem from '../Pack/PackItem'
import { useState, useEffect } from 'react'    
import useData from '../../hooks/useData'


const Popular = () => {

    const { packages } = useData()

    const [popularPackage, setPopularPackage] = useState([]);

    useEffect(()=>{
        let popularPack = packages.filter((pack)=> pack.rating === 5)
        setPopularPackage(popularPack)
    },[packages])

   

    return(
        <section className="main container section">
            <div className="mainTitle">
                <h3 className="mainMainTitle">
                    Popular Packages
                </h3>
            </div>
            

            <div className="mainSectionContent grid">
                {
                    popularPackage.map((data) => 
                        <PackageItem
                            key={data.packId}
                            data={data}
                        />
                    )
                }
            </div>

        </section>
    )
}





export default Popular
