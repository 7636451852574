import { Outlet } from "react-router-dom"
import { DataProvider } from "../../context/DataProvider"
import { Suspense } from "react"



const MainLayout = () => {
  return (
    <div className="App">
       
        <DataProvider>
          <Suspense fallback={<p>Loading...</p>}>
            <Outlet/>   
          </Suspense>    
        </DataProvider>
       
    </div>
  )
}

export default MainLayout