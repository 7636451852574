import { FaFacebook, FaInstagram, FaList, FaLinkedin } from "react-icons/fa"
import Card from './Card'
import { Link } from 'react-router-dom'


const Main = () => {
    
    return(
        <header className="home">
            <div className="overlay"></div>
                <video src='/assets/video.mp4' muted autoPlay loop type='video/mp4'></video>

            <div className='homeContent container'>
                <div className='homeText'>

                    <span className='smallText'>
                        Our Packages
                    </span>

                    <h2 className='homeTitle'>
                        Search your holiday destination.
                    </h2>
                </div>

                <Card />

                <div className="homeFooterIcons flex">
                    <div className="rightIcons">
                        <Link to={`https://www.facebook.com/profile.php?id=61557512890467`}><FaFacebook className='icon'/></Link>
                        <Link to={`https://www.instagram.com/explorersunitedco/`}><FaInstagram className='icon'/></Link>
                        <Link to={`https://www.linkedin.com/company/101684509/`}><FaLinkedin className='icon'/></Link>
                    </div>

                    <div className="leftIcons">
                        <FaList className='icon'/>
                    </div>
                </div>

            </div>
            
        </header>
    )
}

export default Main