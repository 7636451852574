import './hot.css'
import SingleDestination from '../Destination/SingleDestination';
import DataContext from "../../context/DataProvider";
import { useContext, useEffect, useState } from "react"


const Hot = () => {

    const { destinations } = useContext(DataContext);
    
    const [hotDestinations, setHotDestinations] = useState([]);

    useEffect(() => {
        let hotDest = destinations.filter((dest) => (dest.season).toLowerCase() === 'all' || (dest.season).toLowerCase() === 'spring')
        setHotDestinations(hotDest)
    },[destinations])

    return(
        <section className="hot container section">
            
            <div className="hotTitle">
                <h3 className="hotHeading">
                    Hot Locations
                </h3>
            </div>
            

            <div className="hotContent grid">
                {
                    hotDestinations.map((data) => 
                        <SingleDestination
                            key={data.destId}
                            data={data}
                        />
                    )
                }
            </div>
        </section>
    )
}

export default Hot
