import { FaMapPin } from "react-icons/fa";
import { Link } from "react-router-dom";

const SingleDestination = ( { data} ) =>{
    return(
        <div className="topDestination">
            <div className='imageInfo'>
                <img src={data.imgSrc} alt={data.destTitle} />
            </div>

            <div className='topCardInfo'>
                <h4 className='destTitle'>{data.destTitle}</h4>
                <span className='content flex'><FaMapPin className="icon"/> <span className='name'>{data.location}</span> </span>
                
                <div className='desc'>
                    <p>{(data.description).length <=150 ? data.description : `${(data.description.slice(0,150))}...`}</p>
                </div>

                <Link to={`/destinations/details/${data.destId}`}>
                    <button className='btn flex'>
                        Details
                    </button>
                </Link>
            </div>
        </div>

        
    )
}


export default SingleDestination